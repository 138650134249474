import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerBlog'

import pic08 from '../assets/images/added.jpeg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'
import pic11 from '../assets/imgs/landing/tar.jpg'
import pic12 from '../assets/images/Turin.jpg'
import news from '../assets/images/news.png'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
           
             <section id="two" className="spotlights">

             <section>
                    <Link to="/generic" className="image">
                        <img src={news} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter December 2020</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1OS6u_1zwfLy22xB5fRsPabatR3UFpoUY"> See the Newsletter </a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
             <section>
                    <Link to="/generic" className="image">
                        <img src={news} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter September 2020</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=10d1Rmbr0Fm4g68pClAuEUsaDGGZbjuBh"> See the Newsletter </a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>

             <section>
                    <Link to="/generic" className="image">
                        <img src={news} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter June 2020</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1VjaVO_8Ya8dr14ddvWrxSnpG2xUX3RsG"> See the Newsletter </a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>

             <section>
                    <Link to="/generic" className="image">
                        <img src={news} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter 30th March 2020</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1p0e1m8kJ7d9O_6iVe_aZA-Z6mpty7hkH"> See the Newsletter </a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>


             <section>
                    <Link to="/generic" className="image">
                        <img src={news} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter 20th December 2019</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=10cYa9epl3HD5nc_rierACBIwxygH52a0"> See the Newsletter</a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>



             <section>
                    <Link to="/generic" className="image">
                        <img src={pic12} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Turin Agenda</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1nSUqskBDom5EBfZXoYfR54xkTaWt0nbW"> See the Agenda </a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
             <section>
                    <Link to="/generic" className="image">
                        <img src={news} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter October</h3>
                            </header>
                            <ul className="actions">
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=178E_a_bmFBmcK3Ja6My3iw3arcGxV3xc"> See the Newsletter</a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
             <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>News</h3>
                            </header>
                            <ul className="actions">
                                <li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1drUdwaorMs_NvjVR1WKfEbHaXCKQApVb"> News 1 </a>
</li>
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1QkYqj52bbE65Cg2lyOfKn5brsG_ZPY__"> News 2</a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
             <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Tarrega meeting</h3>
                            </header>
                            <ul className="actions">
                                <li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=11YulOd58CTTO1vFfP9VVVlBzFcFaIMuB"> Link to Flyer </a>
</li>
<li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1SSb81tpxjMLcggRZ-5pWEWyEtQTTNZ_J"> Link to Timetable </a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter July 2019</h3>
                            </header>
                            <ul className="actions">
                                <li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=14pObWL55ChXJXErBMCE8zED5WWzeUb9K"> Link to Newsletter</a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletter March 2019</h3>
                            </header>
                            <ul className="actions">
                                <li><a target= "_blank" className = "button" href= "https://drive.google.com/open?id=1u-eD-g4_me4pBGMIw_XM2NASAeIY1lUw"> Link to Newsletter</a>
</li>
                            </ul>
                        </div>
                    </div>
                </section>
                
             
            </section> 
        </div>

    </Layout>
)

export default Landing